import { Card, CardContent, Grid} from '@material-ui/core'
import { Button, TextField, InputLabel, Modal, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Select, MenuItem, FormControl } from '@mui/material/';
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { errorToast, successToast } from "../../shared/notification";
import LoadingSpinner from '../../shared/Spinner/LoadingSpinner';
import { Link, useParams } from "react-router-dom";

import mqtt from "mqtt/dist/mqtt";

const MQTT_USERNAME = process.env.REACT_APP_MQTT_USERNAME
const MQTT_PASSWORD = process.env.REACT_APP_MQTT_PASSWORD
const MQTT_SERVER = process.env.REACT_APP_MQTT_SERVER
const MQTT_PORT = process.env.REACT_APP_MQTT_PORT

const SessionSetting = () => {
    let device = 'ar00xx'
    let device_id = 'LWZA0i'
    const [loading, setLoading] = useState(false)
    const [sessionSettings, setSessionSettings] = useState(defaultSessionData)
    const [blockSettings, setBlockSettings] = useState(defaultBlockData)
    
    const [recipeList, setRecipeList] = useState([])
    const [recipe, setRecipe] = useState({
        id: 0,
        name: ''
    })

    const [toggleAddModal, setToggleAddModal] = useState(false)
    
    const [newRecipeName, setNewRecipeName] = useState('')
    
    const [showForm, setShowForm] = useState(false)

    
    useEffect(() => {
        getRecipeList()
    }, [])
    


    function getRecipeList() {
        setLoading(true)
        axios.get(`https://api.cl-ds.com/recipe/read/${device}/?format=json`, {
            headers: { 'Authorization': 'Token 59ee204b9bbf777b0cd544bcc73b3bc2fcd10f9e' },
        }).then(res=> {
            setLoading(false)
            console.log(12, res.data)
            setRecipeList(res.data)
            // setRecipe(res.data[0])
            // if (typeof JSON.parse(res.data[0].payload.replace(';','')).block == 'undefined') {
                setBlockSettings(defaultBlockData)
                setSessionSettings(defaultSessionData)
            // } else {
            //     setBlockSettings(JSON.parse(res.data[0].payload.replace(';','')).block)
            //     setSessionSettings(JSON.parse(res.data[0].payload.replace(';','')).session)
            // }

        }).catch(err=> {
            setLoading(false)
            console.log(err)
        })
    }
    
    const loadFromController=()=> {
        setShowForm(true)
        // wss://servertwo.cloudatik.com:9001
        // wss://imre.cloudatik.com:8192
        let srrdPayload = `{"messageId":"${device_id}","devices":"session","mode":1}`

        console.log(srrdPayload)
    
        let server = `wss://${MQTT_SERVER}:${MQTT_PORT}`
        // console.log(`*** connecting to ${server}`);
        let client = mqtt.connect(server, {
            username: MQTT_USERNAME,
            password: MQTT_PASSWORD
        })

        client.on('connect', function () {
            console.log("*** connected.");
            console.log(`*** subscribing to farm/srrp/${device_id}...`);
            client.subscribe('farm/srrp/' + device_id, function (err) {
                setLoading(true)
                console.log(123, err)
                if (!err) {
                    console.log(`*** subscribed to farm/srrp/${device_id}.`);
                    console.log(`*** publishing to farm/srrd/${device_id}...`);
                    client.publish('farm/srrd/' + device_id, srrdPayload);
                }
            })
        })

        client.on('message', function (topic, message) {
            setLoading(false)
            const responseMessage = JSON.parse(message);
            console.log(`*** message received from topic: ${topic}.`);
            // console.log(`*** responseMessage:`, responseMessage.devices);
            client.end();

            let data = defaultSessionData

            if (responseMessage.messageId == device_id) {
                let session_array = ['a', 'b', 'c', 'd', 'e', 'f', 'end']
    
                for (let x in session_array) {
                    if (session_array[x] == 'end') {
                        data[session_array[x]]["sc_h"] = responseMessage.devices[0].deviceData["sc_h_" + session_array[x]]
                        data[session_array[x]]["sc_m"] = responseMessage.devices[0].deviceData["sc_m_" + session_array[x]]
                    } else {
                        data[session_array[x]]["io_sc"] = parseInt(responseMessage.devices[0].deviceData["io_sc_" + session_array[x]])
                        data[session_array[x]]["sc_h"] = responseMessage.devices[0].deviceData["sc_h_" + session_array[x]]
                        data[session_array[x]]["sc_m"] = responseMessage.devices[0].deviceData["sc_m_" + session_array[x]]
                    }
                }
            }
            setSessionSettings({...data})

        })
    }

    const handleSwitchChange =(e, session, field)=> {
        let val = 0
        if (sessionSettings[session][field] == 0) {
            val = 1
        }
        setSessionSettings( prev => ({
            ...prev,
            [session]: {
                ...prev[session],
                [field]: val
            }
        }))
    }

    const uploadToController =()=> {
        let server = `wss://${MQTT_SERVER}:${MQTT_PORT}`
        // console.log(`*** connecting to ${server}`);
        let client = mqtt.connect(server, {
            username: MQTT_USERNAME,
            password: MQTT_PASSWORD
        })




        let data = {}
        for (let i of Object.keys(sessionSettings)) {
            if ("io_sc_" + i == "io_sc_end") continue
            
            data["io_sc_" + i] = sessionSettings[i]["io_sc"]
            data["sc_h_" + i] = sessionSettings[i]["sc_h"]
            data["sc_m_" + i] = sessionSettings[i]["sc_m"]
        }
        
        let aswrPayload_2 = `{"messageId": "CHHMOD","devices": [  { "deviceId": "manual", "deviceData": { "mode" : 1, "bypass": 1 } }, {"deviceId":"session", "deviceData": ${JSON.stringify({
            ...data,
        })} },  {  "deviceId": "manual", "deviceData": { "mode" : 0, "bypass": 0 } } ]}`

        setLoading(true)
        client.on('connect', function () {
            console.log(`*** subscribing to farm/aswa/${device_id}`)
            client.subscribe('farm/aswa/' + device_id, function (err) {
                if (!err) {
                    console.log(`*** subscribed to farm/aswa/${device_id}`)
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_2)
                    client.publish('farm/aswr/' + device_id, aswrPayload_2)
                    // client.publish('farm/aswr/' + device_id, aswrPayload_2)
                }
            });
        });

        client.on('message', function (topic, message) {
            setLoading(false)
            console.log("*** received from topic: ", topic)
            console.log("*** received message", message.toString())
            // message is Buffer
            client.end()

            const message_json = JSON.parse(message.toString())

            console.log(123, message_json)

            // if (message_json.messageId == 'PAYLOAD1') {
            //     client.publish('farm/aswr/' + device_id, aswrPayload_2)
            // }
            
            if (message_json.result && message_json.result === "error") {
                errorToast(`Please resubmit.`);
            } else {
                successToast("Values updated.")

                setTimeout(()=>{
                    window.location.reload()
                }, 2200)
            }
        })
    }

    const minutePadding =(min)=>{
        if (min.toString().length < 2) {
            return '0' + min.toString()
        }
        return min.toString()
    }

    const handleInputChange=(e, session, field)=>{
        // Inputs Validation
        if (field == 'sc_h') {
            if (e.target.value > 23 || e.target.value < 0) {
                return errorToast('Invalid hours input.')
            }
        } else if (field == 'sc_m') {
            if (e.target.value > 59 || e.target.value < 0) {
                return errorToast('Invalid minutes input.')
            }
        }
        setSessionSettings( prev => ({
            ...prev,
            [session]: {
                ...prev[session],
                [field]: parseInt(e.target.value)
            }
        }))
    }

    
    function update() {
        if (recipe.id == 0) return errorToast("Please select a Recipe")

        let data = {
            name: recipe.name,
            device: recipe.device,
            payload: JSON.stringify({
                session: sessionSettings,
                block: blockSettings
            })
        }

        setLoading(true)
        axios.post(`https://api.cl-ds.com/recipe/update/${recipe.id}/`, data,{
            headers: {
                'Authorization': 'Token 59ee204b9bbf777b0cd544bcc73b3bc2fcd10f9e',
                "Content-Type": "application/json",
            }
        }).then(res=> {
            setLoading(false)
            successToast('Successfully Updated')
            window.location.reload()
        }).catch(err=>{
            setLoading(false)
            errorToast("Something went wrong. Please contact Administrator.")
        })
    }

    function handleDelete() {
        if (recipe.id == 0) return errorToast("Please select a Recipe")
        setLoading(true)
        axios.delete(`https://api.cl-ds.com/recipe/delete/${recipe.id}/`, {
            headers: {
                'Authorization': 'Token 59ee204b9bbf777b0cd544bcc73b3bc2fcd10f9e',
                "Content-Type": "application/json",
            }
        }).then(res=> {
            setLoading(false)
            successToast('Successfully Deleted')
            window.location.reload()
        }).catch(err=>{
            setLoading(false)
            errorToast("Something went wrong. Please contact Administrator.")
        })
    }
    
    const onChangeRecipe = (e) => {
        setShowForm(true)
        recipeList.map( r => {
            if (r.name == e.target.value) {
                setRecipe(r)
                setBlockSettings(JSON.parse(r.payload.replace(';','')).block)
                setSessionSettings(JSON.parse(r.payload.replace(';','')).session)
            }
        })

    }

    
    function create() {
        setLoading(true)
        axios.post(`https://api.cl-ds.com/recipe/create/`, {
            "payload": JSON.stringify(defaultCreatePayload),
            "name": newRecipeName,
            "device": device
        }, {
            headers: {
                'Authorization': 'Token 59ee204b9bbf777b0cd544bcc73b3bc2fcd10f9e',
                "Content-Type": "application/json",
            }
        }).then(res=> {
            setLoading(false)
            successToast('Successfully Added')
            window.location.reload()
        }).catch(err=>{
            setLoading(false)
            errorToast("Something went wrong. Please contact Administrator.")
        })
    }

    return (
        <>
        {loading && <LoadingSpinner/>}
        <Card style={{
                maxWidth: '80%',
                margin: 'auto'
            }}>
            <CardContent>

            <Grid container spacing={2} style={{marginBottom: '1rem'}}>
                    <Grid item xs={12}>
                        <Button color='primary' fullWidth variant="outlined" size="large" onClick={()=>{
                            loadFromController()
                        }}>Load From Controller</Button>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="recipe-label">Recipe</InputLabel>
                            <Select
                                labelId="recipe-label"
                                id="recipe-select-helper"
                                label="Recipe"
                                value={recipe.name ?? " "}
                                // label="Recipe"
                                onChange={(e)=> {
                                    onChangeRecipe(e)
                                }}>
                                {recipeList.map(r => {
                                    return (
                                        <MenuItem key={r.id} data-device-id={r.device} value={r.name}>{r.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Button color='success' fullWidth variant="outlined" onClick={()=>{update()}}>Save</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button color='error' fullWidth variant="outlined" onClick={()=>{ 
                            if (window.confirm('Are you sure you want to delete?')) {
                                handleDelete()
                            }
                        }}>Delete</Button>
                    </Grid>
                    
                    <Grid item xs={6}>
                        <Link to="/remote-agrirrig-rad/block">
                            <Button color='info' fullWidth variant="contained">Block Setting</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Link to="/remote-agrirrig-rad/session">
                            <Button color='warning' fullWidth variant="contained">Session Setting</Button>
                        </Link>
                    </Grid>

                    {showForm && (
                        <Grid container spacing={2}>
                        <Grid item xs={12} style={{margin:'1rem 0'}}>
                            <h3>Sessions Time Setting</h3>
                        </Grid>

                        <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                                <h5>Session 1</h5>
                            </div>
                            <div className="col-12 col-sm-4">
                                <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["a"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e, 'a', 'sc_h')}}/>
                                <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["a"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e, 'a', 'sc_m')}}/>
                            </div>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', textAlign: 'center', margin:'1rem 0'}}>
                                <Switch color="primary" checked={sessionSettings["a"]["io_sc"] == 0 ? false : true} value={sessionSettings["a"]["io_sc"]} onClick={(e)=>{handleSwitchChange(e, 'a', 'io_sc')}}/>
                            </div>
                        </div>

                        
                        <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                                <h5>Session 2</h5>
                            </div>
                            <div className="col-12 col-sm-4">
                                <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["b"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e, 'b', 'sc_h')}}/>
                                <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["b"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e, 'b', 'sc_m')}}/>
                            </div>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', textAlign: 'center', margin:'1rem 0'}}>
                                <Switch color="primary" checked={sessionSettings["b"]["io_sc"] == 0 ? false : true} value={sessionSettings["b"]["io_sc"]} onClick={(e)=>{handleSwitchChange(e, 'b', 'io_sc')}}/>
                            </div>
                        </div>

                        
                        <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                                <h5>Session 3</h5>
                            </div>
                            <div className="col-12 col-sm-4">
                                <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["c"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e,'c', 'sc_h')}}/>
                                <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["c"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e,'c', 'sc_m')}}/>
                            </div>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', textAlign: 'center', margin:'1rem 0'}}>
                                <Switch color="primary" checked={sessionSettings["c"]["io_sc"] == 0 ? false : true} value={sessionSettings["c"]["io_sc"]} onClick={(e)=>{handleSwitchChange(e, 'c', 'io_sc')}}/>
                            </div>
                        </div>



                        <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                                <h5>Session 4</h5>
                            </div>
                            <div className="col-12 col-sm-4">
                                <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["d"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e, 'd', 'sc_h')}}/>
                                <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["c"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e,'d', 'sc_m')}}/>
                            </div>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', textAlign: 'center', margin:'1rem 0'}}>
                                <Switch color="primary" checked={sessionSettings["d"]["io_sc"] == 0 ? false : true} value={sessionSettings["d"]["io_sc"]} onClick={(e)=>{handleSwitchChange(e, 'd', 'io_sc')}}/>
                            </div>
                        </div>


                        <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                                <h5>Session 5</h5>
                            </div>
                            <div className="col-12 col-sm-4">
                                <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["e"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e, 'e', 'sc_h')}}/>
                                <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["e"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e, 'e', 'sc_m')}}/>
                            </div>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', textAlign: 'center', margin:'1rem 0'}}>
                                <Switch color="primary" checked={sessionSettings["e"]["io_sc"] == 0 ? false : true} value={sessionSettings["e"]["io_sc"]} onClick={(e)=>{handleSwitchChange(e, 'e', 'io_sc')}}/>
                            </div>
                        </div>

                        
                        <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                                <h5>Session 6</h5>
                            </div>
                            <div className="col-12 col-sm-4">
                                <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["f"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e, 'f', 'sc_h')}}/>
                                <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["f"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e, 'f', 'sc_m')}}/>
                            </div>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', textAlign: 'center', margin:'1rem 0'}}>
                                <Switch color="primary" checked={sessionSettings["f"]["io_sc"] == 0 ? false : true} value={sessionSettings["f"]["io_sc"]} onClick={(e)=>{handleSwitchChange(e, 'f', 'io_sc')}}/>
                            </div>
                        </div>
                        
                        
                        <div className='col-12 col-md-8 mb-2 row' style={{display:'flex', margin:'auto', border: '1px solid black', padding: 10, borderRadius: '10px'}}>
                            <div className="col-12 col-sm-4" style={{alignSelf: 'center', margin:'1rem 0'}}>
                                <h5>End Session</h5>
                            </div>
                            <div className="col-12 col-sm-4">
                                <TextField label="Hour" type='number' variant="outlined" size="small" value={sessionSettings["end"]["sc_h"]} style={{width:'100%', marginBottom:'1.2rem' }} onChange={(e)=>{ handleInputChange(e, 'end', 'sc_h')}}/>
                                <TextField label="Minutes" type='number' variant="outlined" size="small" value={minutePadding(sessionSettings["end"]["sc_m"])} style={{width:'100%'}} onChange={(e)=>{ handleInputChange(e, 'end', 'sc_m')}}/>
                            </div>
                            <div className="col-12 col-sm-4" style={{visibility: 'hidden'}}>
                                <Switch color="primary"/>
                            </div>
                        </div>
                        
                        <Grid item xs={6}>
                            <Button color='warning' fullWidth variant="outlined" onClick={()=>{uploadToController()}}>Upload to Controller</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button color='success' fullWidth variant="outlined" onClick={()=>setToggleAddModal(true)}>Create New Recipe</Button>
                        </Grid>
                    </Grid>
                    )}
                    
                </Grid>

            </CardContent>
        </Card>
        <Dialog open={toggleAddModal} maxWidth="md" fullWidth={true}>
            <DialogTitle>Create New Recipe</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Enter new Recipe Name
            </DialogContentText>
            <TextField type='text' autoFocus id="outlined-basic" fullWidth label="Recipe Name" variant="standard" value={newRecipeName} onChange={(e)=>{ setNewRecipeName(e.target.value) }}/>
            </DialogContent>
            <DialogActions>
            <Button onClick={()=>setToggleAddModal(false)}>Cancel</Button>
            <Button onClick={()=>create()}>Create</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

const defaultSessionData = {
    a: { io_sc : 0, sc_h: '0', sc_m: '0'},
    b: { io_sc : 0, sc_h: '0', sc_m: '0'},
    c: { io_sc : 0, sc_h: '0', sc_m: '0'},
    d: { io_sc : 0, sc_h: '0', sc_m: '0'},
    e: { io_sc : 0, sc_h: '0', sc_m: '0'},
    f: { io_sc : 0, sc_h: '0', sc_m: '0'},
    end: {sc_h : '0', sc_m: '0'}
}

const defaultBlockData = {
    1 : { 
        a: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        b: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        c: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        d: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        e: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        f: { io_sc : '0', duration: '', medium: '0', sppar: ''},
    },
    2 : { 
        a: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        b: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        c: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        d: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        e: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        f: { io_sc : '0', duration: '', medium: '0', sppar: ''},
    },
    3 : { 
        a: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        b: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        c: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        d: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        e: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        f: { io_sc : '0', duration: '', medium: '0', sppar: ''},
    },
    4 : { 
        a: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        b: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        c: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        d: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        e: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        f: { io_sc : '0', duration: '', medium: '0', sppar: ''},
    },
    5 : {
        a: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        b: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        c: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        d: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        e: { io_sc : '0', duration: '', medium: '0', sppar: ''},
        f: { io_sc : '0', duration: '', medium: '0', sppar: ''},
    },
}
const defaultCreatePayload = {
    "session" : defaultSessionData,
    "block": defaultBlockData
}
export default SessionSetting