import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { Col, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { TextField, Grid, Switch } from '@material-ui/core';
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import EditProfile from "../../Components/Card/single-node/EditProfile";
import { useParams } from 'react-router-dom'
import RemoveNode from "../../Components/Card/single-node/RemoveNode";
import axiosInstance from "../../shared/api";
import DataTable from 'react-data-table-component';


function NodeDetail() {
    let { device_id } = useParams()
    const [loading, setLoading] = useState(false)

    const [type, setType] = useState("qr") // qr | hardware | remove

    const [labels, setLabels] = useState([]) // [{ "EC Sensor": "LECP 65 (Warranty Expiry: 2024-10-31 [EXPIRED])"}]
    const [showHistory, setShowHistory] = useState(false)
    const [serialName, setSerialName] = useState(null)
    const [histories, setHistories] = useState([])
    const [searchTexts, setSearchTexts] = useState({}); // Object to store search text for each category
    
    useEffect(() => {
        axiosInstance.get(`https://api.cloudatik.com/node/v1.0/hardware/${device_id}`).then(res =>{
            console.log(123, res.data)
            setSerialName(res.data.serial)
            setLabels(res.data.current)
            setHistories(res.data.history)
        }).catch(err => {
            console.log(234, err)
        })
    }, [])
    

    const generateColumns = (data) => {
        if (data.length === 0) return [];
        const keys = Object.keys(data[0]);
        return keys.map(key => ({
            name: key.replace(/_/g, " ").toUpperCase(),
            selector: row => row[key],
            sortable: true,
            wrap: true, // Enable text wrapping
            // minWidth: "110px", // Set a wider width for the fixed column
            style: key === "name" ? { position: "sticky", left: 0, backgroundColor: "#ECECEC ", zIndex: 2 } : {}, // Fix "name" column
        }))
    }

    // Function to filter data based on search text
    const filterData = (data, searchText) => {
        if (!searchText) return data;
        return data.filter(item =>
            Object.values(item).some(value => String(value).toLowerCase().includes(searchText.toLowerCase())
        ))
    };

    // Function to handle search text change for a specific category
    const handleSearchTextChange = (category, text) => {
        setSearchTexts(prevState => ({
            ...prevState,
            [category]: text,
        }));
    };


	

	return (
		<div>
        {loading && <LoadingSpinner />}
            <Grid container spacing={1} style={{ marginBottom: 20, display: 'flex', justifyContent: "space-evenly", paddingLeft: "1rem", paddingRight: "1rem"}}>
                <Grid item xs={4} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    <Button variant="contained" size="small" color="primary" style={{ width: '100%', height: '100%', fontSize: "13px"}} onClick={()=>setType("qr")}>QR</Button>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    <Button variant="contained"  color="secondary" style={{ width: '100%', height: '100%', fontSize: "13px"}} onClick={()=>setType("hardware")}>Hardware</Button>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    <Button variant="contained" size="small" color="warning" style={{ width: '100%', height: '100%', fontSize: "13px"}} onClick={()=>setType("remove_node")}>Remove Profile</Button>
                </Grid>
            </Grid>

            <center style={{marginTop: "1rem", paddingLeft: "2rem", paddingRight: "2rem", marginBottom: "2rem"}}>
                <h3>{serialName || "-"}</h3>
            </center>
            
            {type == "hardware" && (
            <Grid container style={{ display: "flex", justifyContent: "center", paddingBottom: "3rem", }}>
                <Grid className="card" item xs={11} sm={10} style={{marginBottom: "2rem"}}>
                    <div className="card-header">
                        <h5 className="card-title">Hardware</h5>
                    </div>

                    <div className="card-body">
                        <div style={{marginTop:"10px"}}>
                        {Object.entries(labels).map(([key, value], index) => (
                            <div className="form-group" key={index}>
                                <label>{key}</label> 
                                <input type="text" className="form-control" value={value} disabled /> 
                            </div>
                        ))}
                            
                        </div>
                                
                        <div style={{display:"flex", justifyContent:"center" , marginTop: "2rem",}}>
                            <Button type="button" variant="contained" color="warning" onClick={()=>setShowHistory(!showHistory)}>History</Button>
                        </div>
                        {showHistory && (
                            <div style={{ marginTop: "2rem" }}>
                                {Object.entries(histories).map(([category, records]) => (
                                <div key={category} style={{marginTop:"1rem"}}>
                                    <div style={{ textTransform: "capitalize", marginBottom: "1rem", fontSize: "20px", fontWeight:"600" }}>{category}</div>
                                    <DataTable
                                        columns={generateColumns(records)}
                                        data={filterData(records, searchTexts[category] || "")}
                                        // pagination
                                        // highlightOnHover
                                        responsive
                                        subHeader
                                        subHeaderComponent={
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                value={searchTexts[category] || ""}
                                                onChange={e => handleSearchTextChange(category, e.target.value)}
                                                style={{ width: '100%', padding: '2px 8px', border: "1px solid", width: "50%" }}
                                            />
                                        }
                                        customStyles={{
                                            headCells:{
                                                style: (e) => {
                                                    if (e["data-column-id"] === 1) {
                                                        return { position: "sticky", left: 0, backgroundColor: "#FFF ", zIndex: 2, margin: "auto", paddingLeft: "1rem" } 
                                                    }
                                                }
                                            },
                                            cells: {
                                                style: {
                                                    whiteSpace: 'normal', // Ensure text wraps
                                                    wordBreak: 'break-word', // Break long words
                                                },
                                            },
                                        }}
                                    />
                                </div>
                                ))}
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>
            )}

            {type == "qr" && (
            <Grid container style={{ display: "flex", justifyContent: "center", paddingBottom: "3rem"}}>
                <Grid className="card" item xs={10} s={8} style={{marginBottom: "2rem"}}>
                    <div className="card-header">
                        <h5 className="card-title">QR</h5>
                    </div>
                    <div className="card-body">
                        <EditProfile device_id={device_id} setOpenProfileNode={true}/>
                    </div>
                </Grid>
            </Grid>
            )}

            {type == "remove_node" && (
            <Grid container style={{ display: "flex", justifyContent: "center", paddingBottom: "3rem"}}>
                <Grid className="card" item xs={10} s={8} style={{marginBottom: "2rem"}}>
                    <div className="card-header">
                        <h5 className="card-title">Remove Node</h5>
                    </div>
                    <div className="card-body">
                        <RemoveNode device_id={device_id}/>
                    </div>
                </Grid>
            </Grid>
            )}
		</div>
	);
}

export default NodeDetail
